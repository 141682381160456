import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';

import SmartLink from '../SmartLink';
import ToolTip from '../ToolTip';
import ImgixImage from '../ImgixImage';
import useMobile from '../useMobile';
import { sortBy } from '../../lib/nodash';
import { getProductPath, findMetafieldValue } from '../../lib/product';
import findImage from '../../lib/findImage';

const Colorway = ({ product, patternTitle, isActive }) => {
  const isMobile = useMobile();
  const hexValue = findMetafieldValue('hex_color', product.metafields);
  const image =
    findImage(product.media, '_detail') || findImage(product.media, '_slide3');
  return (
    <SmartLink to={getProductPath(product)} a11yTitle={product.title}>
      <ToolTip
        key={product.id}
        content={product.title.replace(patternTitle, '').replace('-', '')}
      >
        <Box
          width={'50px'}
          height={'50px'}
          background={hexValue || 'light-2'}
          border={{
            side: 'all',
            size: isActive ? 'small' : '1px',
            color: isActive ? 'black' : 'transparent',
          }}
          overflow="hidden"
        >
          <ImgixImage
            src={image}
            alt={product.title}
            options={{
              w: 50,
              h: 50,
              fit: 'crop',
              ar: '1:1',
              q: 90,
              dpr: 2,
              auto: ['format', 'compress'],
              cs: 'srgb',
            }}
          />
        </Box>
      </ToolTip>
    </SmartLink>
  );
};

Colorway.propTypes = {
  product: PropTypes.object,
  patternTitle: PropTypes.string,
  isActive: PropTypes.bool,
};

const WallcoveringsAlternateColorwaysList = ({ product, collection }) => {
  if (!collection) {
    return null;
  }
  const patternTitle = collection.title;
  const products = collection.products;
  const isMobile = useMobile();
  return (
    <Box gap="small">
      <Heading level={5}>Available Colorways</Heading>
      <Box direction="row" gap={isMobile ? 'medium' : 'small'}>
        {sortBy('title', products).map((x) => (
          <Colorway
            key={x.id}
            isActive={x.id === product.id}
            product={x}
            patternTitle={patternTitle}
          />
        ))}
      </Box>
    </Box>
  );
};

WallcoveringsAlternateColorwaysList.propTypes = {
  product: PropTypes.object,
  collection: PropTypes.object,
};

export default memo(WallcoveringsAlternateColorwaysList);
